body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.auth-form {
    max-width: 500px;
}

.loading-wrapper{
    margin: auto;
    width: 50%;
    padding: 10px;
    top:35%;
    position: relative;
}

.loading-wrapper h4, .loading-wrapper h6 {

    text-align: center;
}

.breathing-icon {
    width: 120px;
    -webkit-animation: breathing 1s ease-out infinite normal;
    animation: breathing 1s ease-out infinite normal;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    text-align: center;    
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.shaking-icon {
  width: 120px;
  -webkit-animation: shake 2s ease-out infinite normal;
  animation: shake 2s ease-out infinite normal;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  text-align: center;    
  display: block;
  margin-left: auto;
  margin-right: auto;
}


@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@-webkit-keyframes shake {
  10%, 90% {
    -webkit-transform:  rotate(0deg);
            transform:  rotate(0deg);
  }
  
  20%, 80% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }

  30%, 50%, 70% {
    -webkit-transform:rotate(-15deg);
            transform:rotate(-15deg);
  }

  40%, 60% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }
}

@keyframes shake {
  10%, 90% {
    -webkit-transform:  rotate(0deg);
            transform:  rotate(0deg);
  }
  
  20%, 80% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }

  30%, 50%, 70% {
    -webkit-transform:rotate(-15deg);
            transform:rotate(-15deg);
  }

  40%, 60% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }
}
main.coffee-batch-detail{
    margin-left:auto;
    margin-right:auto;
}
.user-details__avatar {
  border-radius: 50%;
  max-width: 100%;
  box-shadow: none;
  text-align: center;
}

.user-details__avatar img {
  width: auto;
  object-fit: cover;
  border-radius: 50%;
  width: 115px;
  height: 115px;
}

.user-avatar {
  max-height: 115px;
}

.identicon {
  vertical-align: middle;
  border-style: none;
}

.burner-button {
  background-color: rgb(247, 107, 28);
  color: rgb(255, 255, 255);
  white-space: nowrap;
  cursor: pointer;
  border-color: rgb(247, 107, 28);
}

.burner-button:hover,
button.btn.burner-button.btn.btn-secondary {
  background-color: rgb(253, 108, 25);
  color: rgb(255, 255, 255);
  white-space: nowrap;
  cursor: pointer;
  border-color: rgb(253, 108, 25);
}

.title-link a {
  color: #332211;
}

.user-teams__image {
  width: auto;
  object-fit: cover;
  border-radius: 50%;
  width: 56px;
  height: 56px;
}

.user-teams__image img {
  width: auto;
  object-fit: cover;
  border-radius: 50%;
  width: 56px;
  height: 56px;
}

#navbar-link{
    line-height: 25px
}
#main-logo{
    max-width: 150px
}
.main-navbar .navbar .user-avatar {
    max-height: 2.5rem;
}

.identicon{
    vertical-align: middle;
    border-style: none;
}
div.modalDiv{
    text-align: center;
}
a.modalLink{
    color:white;
    font-size:1.5em;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Select File";
}
div.affogato{
    background-color:#332211!important;
}
