.user-details__avatar {
  border-radius: 50%;
  max-width: 100%;
  box-shadow: none;
  text-align: center;
}

.user-details__avatar img {
  width: auto;
  object-fit: cover;
  border-radius: 50%;
  width: 115px;
  height: 115px;
}

.user-avatar {
  max-height: 115px;
}

.identicon {
  vertical-align: middle;
  border-style: none;
}

.burner-button {
  background-color: rgb(247, 107, 28);
  color: rgb(255, 255, 255);
  white-space: nowrap;
  cursor: pointer;
  border-color: rgb(247, 107, 28);
}

.burner-button:hover,
button.btn.burner-button.btn.btn-secondary {
  background-color: rgb(253, 108, 25);
  color: rgb(255, 255, 255);
  white-space: nowrap;
  cursor: pointer;
  border-color: rgb(253, 108, 25);
}
