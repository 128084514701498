.title-link a {
  color: #332211;
}

.user-teams__image {
  width: auto;
  object-fit: cover;
  border-radius: 50%;
  width: 56px;
  height: 56px;
}

.user-teams__image img {
  width: auto;
  object-fit: cover;
  border-radius: 50%;
  width: 56px;
  height: 56px;
}
